<template>
  <div class="SpecificationManagement">
    <h3>农资规格管理</h3>
    <!-- 新增和编辑 -->
    <addEdit v-if="showEdit" @closeDialog="closeDialog" ref="addEdit" />
    <!-- 导入新的数据库 -->
    <!-- <TotalDatabase
      v-if="showImport"
      @closeDialog2="closeDialog2"
      ref="showImport"
    /> -->
    <!-- 新增子集节点 -->
    <addNode v-if="showNode" @closeDialog3="closeDialog3" ref="showNode"/>
    <div class="tables">
      <div class="tree">
        <el-tree
          :data="childrenList"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span @click="ChangeTab(data)">{{ node.label }}</span>
            <span>
              <el-button
                type="text"
                size="mini"
                v-if="node.parent.level == 0"
                @click="() => append(data)"
              >
                新增
              </el-button>
            </span>
          </span>
        </el-tree>
      </div>
      <div class="assets">
        <!-- 根据条件搜索    -->
        <div class="searchItem">
          <el-form
            :model="form"
            label-position="center"
            label-width="80px"
            inline
            ref="form"
          >
            <el-form-item label="品牌：">
              <el-input
                style="width: 190px"
                v-model="form.brand"
                placeholder="请填写品牌"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="型号：">
              <el-input
                style="width: 190px"
                v-model="form.model"
                placeholder="请填写型号"
              >
              </el-input>
            </el-form-item>
            <div>
              <el-button class="query-btn" type="primary" @click="search"
                >搜索</el-button
              >
              <el-button type="primary" @click="add">添加新农资</el-button>
              <!-- <el-button type="primary" @click="imports"
                >导入新村通总库农贸</el-button
              > -->
            </div>
          </el-form>
        </div>

        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.pnum"
          :pageSize="query.psize"
          :total="total"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        >
          <template slot="operation" slot-scope="scope">
            <el-button type="text" size="small" @click="editClick(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deleteBtn(scope.row.agriculturalId)"
              >删除</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>

<script>
import TablePage from "@/components/table/table-page";
import {
  nodeList,
  treeList,
  dataList,
  deleteAgriculturalManagement,
} from "@/api/CollectivePurchase";
import { AgriculturalResourcesManagement } from "../tableColumn";
import addEdit from "./component/addEdit";
import addNode from "./component/addNode";
// import TotalDatabase from "./component/TotalDatabase";

import { mapGetters } from "vuex";
export default {
  // name: "NowIndex",
  data() {
    const data = [];

    return {
      data: JSON.parse(JSON.stringify(data)),
      query: {
        pnum: 1,
        psize: 10,
      },
      total: 0,
      form: {
        brand: "", // 品牌
        model: "", //型号
      },
      keyForm: 1,
      columns: AgriculturalResourcesManagement,
      showEdit: false,
      // showImport: false,
      showNode: false,
      tableData: [],
      oneList: [],
      subsetList: [],
      childrenList: [],
      dataList: [],
      secondId: [],
    };
  },

  components: {
    TablePage,
    addEdit,
    // TotalDatabase,
    addNode,
  },

  computed: {
    // ...mapGetters(["topSelectAreacode"]),
    ...mapGetters(["identityObj"]),
  },
  mounted() {
    //node树查询
    this.nodeList();
    this.treeList();
    this.getList();
  },
  methods: {
    // 关闭弹框
    async closeDialog() {
      this.showEdit = false;

      let params = {
        size: this.query.psize,
        current: this.query.pnum,
        secondId: this.secondId,
      };

      let res = await dataList(params);
      this.tableData = res.data.records;
      this.total = Number(res.data.total);
      // this.getList();
    },
    // closeDialog2() {
    //   this.showImport = false;
    // },
    closeDialog3() {
      this.showNode = false;
      this.treeList();
    },
    //新增子集节点
    append(data) {
      this.showNode = true;
      this.$nextTick(() => {
        this.$refs.showNode.addInit(data);
      });
    },
    //点击切换二级种类
    async ChangeTab(data) {
      this.secondId = data.value;
      let params = {
        size: this.query.psize,
        current: this.query.pnum,
        secondId: data.value,
      };

      let res = await dataList(params);
      // this.form = [];
      this.tableData = res.data.records;
      this.total = Number(res.data.total);
    },

    //table数据
    async getList() {
      let params = {
        size: this.query.psize,
        current: this.query.pnum,
        areaCode: this.topSelectAreacode,
      };
      let res = await dataList(params);
      this.tableData = res.data.records;
      this.total = Number(res.data.total);
    },
    //搜索
    async search() {
      let params = {
        size: this.query.psize,
        current: this.query.pnum,
        ...this.form,
      };
      let res = await dataList(params);
      this.tableData = res.data.records;
      this.total = Number(res.data.total);
    },
    //添加新农资
    add() {
      // 显示发布的弹框
      this.showEdit = true;
      this.$nextTick(() => {
        this.$refs.addEdit.addInit(this.childrenList);
      });
    },
    //编辑
    editClick(obj) {
      this.showEdit = true;
      this.$nextTick(() => {
        console.log(obj, "这里有吗");
        this.$refs.addEdit.Edits(obj, this.childrenList);
      });
    },
    //导入总数据库
    imports() {
      this.showImport = true;
    },
    // 农资规格管理-一级种类字典查询
    async nodeList() {
      let params = "first_crop_type";
      let res = await nodeList(params);
      this.oneList = res.data;
    },
    //农资规格管理-种类树状图查询
    async treeList() {
      this.childrenList = [];
      let params = {
        areaCode: this.identityObj.areaCode,
      };
      let res = await treeList(params);
      this.subsetList = res.data;
      this.allNode();
    },
    //组合成一个节点树的列表
    allNode() {
      let firstList = this.oneList;
      let secondList = this.subsetList;
      this.$nextTick(() => {
        firstList.forEach((item) => {
          const newItem = {
            ...item,
            children: [],
          };
          secondList.forEach((items) => {
            if (item.id === items.parentId) {
              items.label = items.secondName;
              items.value = items.secondId;
              newItem.children.push(items);
            }
          });
          this.childrenList.push(newItem);
        });
        this.childrenList.forEach((item) => {
          item.secondId = item.value;
        });
      });
    },
    //分页单页切换
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.getList();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        pnum: 1,
        psize: size,
      };
      this.getList();
    },
    //删除
    deleteBtn(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteAgriculturalManagement(id);
          this.getList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.SpecificationManagement {
  width: 100%;
  height: 100%;
  h3 {
    padding-left: 20px;
  }
  .tables {
    width: 100%;
    height: 100%;
    display: flex;
    .tree {
      width: 10%;
      border: solid 1px #ccc;
      margin: 0px 5px;
    }
    .assets {
      width: 85%;
      /*根据条件搜索*/
      .searchItem {
        .el-form {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
}
</style>
