<template>
  <el-dialog
    :title="isEdit ? '编辑农资规格' : '新增农资规格'"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="1000px"
  >
    <div>
      <el-form
        ref="ruleForm"
        :inline="true"
        :model="form"
        label-width="120px"
        :rules="formRules"
      >
        <div class="inputBox">
          <div class="Linput">
            <el-form-item
              label="农资类型"
              style="width: 100% !important"
              prop="agriculturalType"
            >
              <!--   :show-all-levels="false" -->
              <el-cascader
                ref="address"
                v-model="form.agriculturalType"
                :options="TypeList"
                @change="handleChange"
                :show-all-levels="false"
                :props="props"
                clearable
              ></el-cascader>
            </el-form-item>
            <el-form-item label="品牌" prop="brand">
              <el-input placeholder="请填写品牌" min="1" v-model="form.brand">
              </el-input>
            </el-form-item>
            <el-form-item label="型号" prop="model">
              <el-input placeholder="请填写型号" min="1" v-model="form.model">
              </el-input>
            </el-form-item>
            <el-form-item label="指导价" prop="guidePrice">
              <el-input
                placeholder="请填写指导价"
                type="number"
                min="1"
                v-model="form.guidePrice"
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="Rinput">
            <!-- <div class="placeholder"></div> -->
            <el-form-item label="生产商" prop="producer">
              <el-input
                placeholder="请填写生产商"
                min="1"
                v-model="form.producer"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="规格" prop="specifications">
              <el-input
                placeholder="请填写规格"
                min="1"
                v-model="form.specifications"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="采购最小单位" prop="unit">
              <el-input
                placeholder="请填写最小采购单位"
                min="1"
                v-model="form.unit"
              >
              </el-input>
            </el-form-item>
          </div>
        </div>
        <!-- 这是其他项 -->
        <div class="other">
          <el-form-item label="其他指标">
            <el-input
              type="textarea"
              placeholder="请输入其他指标"
              v-model="form.otherIndicator"
              maxlength="300"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button style="margin-top: 20px" @click="back">取消</el-button>
      <el-button :disabled="is_click" type="primary" @click="submit(form)">{{
        isEdit ? "保存编辑" : "确定新增"
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  AgriculturalCapital,
  updateAgricultural,
} from "@/api/CollectivePurchase";
export default {
  name: "NowAddEdit",

  data() {
    // var guidePriceType = (rule, value, callback) => {
    //   if (value == "") {
    //     return callback(new Error("限价不能为空"));
    //   }
    // };
    return {
      isEdit: false, //当前页面状态：编辑还是新增
      dialogVisible: true,
      form: {
        agriculturalType: "", //农资类型
        brand: "", //品牌
        model: "", //型号
        producer: "", //生产商
        specifications: "", //规格
        guidePrice: "", //指导价
        unit: "", //采购最小单位
        otherIndicator: "", //其他指标
      },
      TypeList: [],
      formRules: {
        agriculturalType: [
          { required: true, message: "请选择农资类型", trigger: "change" },
        ],
        brand: [{ required: true, message: "请输入品牌", trigger: "blur" }],

        model: [{ required: true, message: "请输入型号", trigger: "blur" }],
        producer: [
          { required: true, message: "请输入生产商", trigger: "blur" },
        ],
        specifications: [
          { required: true, message: "请输入规格", trigger: "blur" },
        ],
        unit: [
          { required: true, message: "请输入采购最小单位", trigger: "blur" },
        ],
        guidePrice: [
          {
            required: true,
            message: "请输入指导价",
            trigger: "blur",
          },
        ],
      },
      typeIndex: [],
      props: {
        value: "secondId",
        label: "label",
        children: "children",
      },
      EditsList: [],
      is_click: false, //防止暴力点击
    };
  },

  mounted() {},

  methods: {
    back() {
      this.$emit("closeDialog", false);
    },
    handleClose() {
      this.$emit("closeDialog", false);
    },
    //回显农资类型
    addInit(obj) {
      console.log(obj, "这是农资的类型");
      this.TypeList = obj;
      obj.forEach((item) => {
        item.secondId = item.value;
      });
    },
    async submit() {
      this.is_click = true;
      this.$refs.ruleForm.validate(async (valid, obj) => {
        console.log(valid, obj, "valid");
        if (valid) {
          if (!this.isEdit) {
            //  农资类型转字符串
            let type1 = this.TypeList.find(
              (its) => its.value == this.typeIndex[0]
            );
            let type2 = type1.children.find(
              (its) => its.secondId == this.typeIndex[1]
            );
            let newAgriculturalType = type1.label + "-" + type2.label;
            // console.log('这是新增',newAgriculturalType)
            this.form.agriculturalType = newAgriculturalType;
            let params = {
              ...this.form,
            };

            params.secondId = this.typeIndex[1];

            let res = await AgriculturalCapital(params);
            if (res.code == 0) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
            this.$emit("closeDialog", false);
          } else {
            this.updateAgricultural();
          }
        } else {
          this.$message.error("请完善发布信息");
          setTimeout(() => {
            this.is_click = false;
          }, 1000);
        }
      });
    },
    // 编辑
    async updateAgricultural() {
      if (typeof this.form.agriculturalType == "object") {
        let oneName = this.TypeList.filter((item) => {
          return item.children.find((its) => its.secondId == this.typeIndex[1]);
        });
        let secondIndex = oneName[0].children.findIndex((item) => {
          return item.secondId == this.typeIndex[1];
        });
        let newAgriculturalType =
          oneName[0].label + "-" + oneName[0].children[secondIndex].label;
        this.form.agriculturalType = newAgriculturalType;
      } else {
        this.form.agriculturalType = this.EditsList.agriculturalType;
      }
      let params = {
        ...this.form,
      };
      params.agriculturalId = this.EditsList.agriculturalId;
      params.secondId = this.typeIndex[1];
      console.log("查看secondId", params.secondId);
      console.log(" params.agriculturalId", this.EditsList.agriculturalId);
      let res = await updateAgricultural(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
        this.$emit("closeDialog", false);
      } else {
      }
    },
    //编辑回显的数据
    Edits(obj, childrenList) {
      this.TypeList = childrenList;
      console.log(this.TypeList, "查看this.typeList");
      this.EditsList = obj;
      console.log(obj, "这是回显的");
      this.isEdit = true;
      this.form = {
        ...obj,
      };
      this.form.agriculturalType = obj.secondId;
      console.log(this.form.agriculturalType, "查看");
    },
    //农资类型下拉框选择
    handleChange(value) {
      this.typeIndex = value;
      console.log(this.typeIndex, "查看this.typeIndex");
      console.log(this.typeIndex);
    },
  },
};
</script>

<style lang="less" scoped>
/* .Rinput {
  .placeholder {
    width: 100%;
    height: 60px;
  }
}*/
/deep/.el-form--inline {
  .inputBox {
    display: flex;
  }

  .other {
    .el-form-item {
      .el-form-item__content {
        width: 700px;
      }
    }
  }
}
</style>
